import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { addHours, parse } from 'date-fns';
import TimeoffDetails from './components/TimeoffDetails';
import PageLoader from '../PageLoader';
import { getClientTzOffset } from '../../helpers/_helpers';
import styles from './styles';
import { useForm } from 'react-hook-form';
import { reasons, relations } from '../Kiosk/components/AddTimeOff/helpers/helpers';
import { getOptions } from 'helpers/getOptions';
import $api from 'http/index';
import { getTimeoffs } from 'store/availability/availabilityPagesOperations';
import { useDispatch } from 'react-redux';
import { useWeekStart } from 'hooks/useWeekStart';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import {
  DAY_PART,
  convertTimeObject,
  convertTo24HourFormat,
} from 'common/dateFormatConfig';
import { useSettingsDefaultTime } from 'common/useSettingsDefaultTime';
import { convertToArr } from 'helpers/_date-helpers';
import { getDate } from 'helpers/getDate';

const EditSeveralTimeoffsForm = ({ data, oldTimeoffId, openSnackbar, closeDrawer, classes }) => {
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const { defaultDayTime } = useSettingsDefaultTime();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      notes: data[0].notes || '',
      isPTO: data[0].isPTO,
      startDate: [data[0].dates[0], data[1].dates[0]],
      endDate: [data[0].dates[1], data[1].dates[1]],
      type: data[0].type,
      reason: data[0].reason,
      relationship: data[0].relationship || '',
      startHours: [
        (data[0].startTime?.hour && `${convertTimeObject(data[0].startTime, is12Format).hour}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).hour}` ||
          '8',
        (data[1].startTime?.hour && `${convertTimeObject(data[1].startTime, is12Format).hour}`) ||
          `${convertTimeObject(defaultDayTime, is12Format).hour}` ||
          '8',
      ],
      startMinutes: [
        (data[0].startTime && data[0].startTime.minute) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
        (data[1].startTime && data[1].startTime.minute) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
      ],
      startAmPm: [
        (data[0].startTime?.hour && convertTimeObject(data[0].startTime, is12Format).amPm) ||
          `${convertTimeObject(defaultDayTime, is12Format).amPm}` ||
          DAY_PART[0],
        (data[1].startTime?.hour && convertTimeObject(data[1].startTime, is12Format).amPm) ||
          `${convertTimeObject(defaultDayTime, is12Format).amPm}` ||
          DAY_PART[0],
      ],
      endHours: [
        (data[0].endTime?.hour && `${convertTimeObject(data[0].endTime, is12Format).hour}`) ||
          `${((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) % 12 || 12}` ||
          '4',
        (data[1].endTime?.hour && `${convertTimeObject(data[1].endTime, is12Format).hour}`) ||
          `${((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) % 12 || 12}` ||
          '4',
      ],
      endMinutes: [
        (data[0].endTime && data[0].endTime.minute) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
        (data[1].endTime && data[1].endTime.minute) ||
          `${convertTimeObject(defaultDayTime, is12Format).minute}` ||
          '00',
      ],
      endAmPm: [
        (data[0].endTime?.hour && convertTimeObject(data[0].endTime, is12Format).amPm) ||
          ((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) > 12 ? DAY_PART[1] : DAY_PART[0] ||
          DAY_PART[1],
        (data[1].endTime?.hour && convertTimeObject(data[1].endTime, is12Format).amPm) ||
          ((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) > 12 ? DAY_PART[1] : DAY_PART[0] ||
          DAY_PART[1],
      ],
      hoursHours: [Math.floor(data[0].hours || 4), Math.floor(data[1].hours || 4)],
      hoursMinutes: [(Math.floor(data[0].hours  * 60) % 60) || 0, Math.floor((data[1].hours  * 60) % 60) || 0],
    },
  });

  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const values = watch();
  const { weekDayFromO } = useWeekStart();

  useEffect(() => {
    const user = data[0].user;
    setUser(user);
  }, []);

  useEffect(() => {
    values.reason === reasons[2] ? setValue('relationship', '') : trigger('notes');
    setValue('notes', '');
  }, [values.reason === reasons[2]]);

  useEffect(() => {
    if (values.reason === reasons[2] && values.relationship !== relations[relations.length - 1]) {
      setValue('isPTO', true);
    }
  }, [values.relationship]);

  const calcUserWeekends = () => {
    if (!user) {
      return [];
    }

    const userWorkDays = user.profile.shifts.weekDays;
    switch (userWorkDays) {
      case 'Sun-Thu':
        return [5, 6];
      case 'Mon-Fri':
        return [0, 6];
      case 'Tue-Sat':
        return [0, 1];
      default:
        return [];
    }
  };

  const onSubmit = async (values) => {
    const commonData = {
      isPTO: values.isPTO,
      notes: values.notes,
      type: values.type,
      reason: values.reason,
    };
    if (values.reason === reasons[2]) {
      commonData.relationship = values.relationship;
      commonData.relativeName = values.notes;
      delete commonData.notes;
    }
    commonData.dates = [commonData, commonData].map((field, i) => {
      let hours, endDate, endHours, endMinutes, endAmPm;
      if (values.type === 'partial') {
        hours = values.hoursHours[i] + Math.round((values.hoursMinutes[i] / 60), 2);

        const [calculatedEndDate, calculatedEndHours, calculatedEndAmPm] = convertToArr(
          addHours(
            getDate(
              values.startDate[i],
              values.startHours[i],
              values.startMinutes[i],
              values.startAmPm?.[i],
              getTimeFormatHook
            ),
            hours,
          ),
          dateFormat,
          getTimeFormatHook
        );

        endHours = `${calculatedEndHours}`.padStart(2, '0')
        endMinutes = `${(values.startMinutes[0] + hours * 60) % 60}`.padStart(2, '0');
        endAmPm = calculatedEndAmPm;
      } else {
        endHours = values?.endHours[i];
        endMinutes = values?.endMinutes[i];
        endAmPm = values?.endAmPm[i];
      }

      return {
        startTime: {
          hour: convertTo24HourFormat(values.startHours[i], values.startAmPm?.[i]),
          minute: values.startMinutes[i],
        },
  
        endTime: {
          hour: convertTo24HourFormat(endHours, endAmPm),
          minute: endMinutes,
        },
        utcStartDate: parse(
          getTimeFormatHook([
            values.startDate[i],
            values.startHours[i],
            values.startMinutes[i],
            values.startAmPm[i] || null,
          ]).formattedTimeDate,
          getTimeFormatHook([
            values.startDate[i],
            values.startHours[i],
            values.startMinutes[i],
            values.startAmPm[i] || null,
          ]).formatForDateTimePars,
          new Date()
        ),
        utcEndDate: parse(
          getTimeFormatHook([
            values.endDate[i],
            +endHours,
            +endMinutes,
            endAmPm || null,
          ]).formattedTimeDate,
          getTimeFormatHook([
            values.endDate[i],
            +endHours,
            +endMinutes,
            endAmPm || null,
          ]).formatForDateTimePars,
          new Date()
        ),
        hours: values?.type === 'partial'
          ? hours
          : undefined
      }
    });

    try {
      const res = await $api.post(
        `${process.env.REACT_APP_BASE_URL}/timeoffs/${oldTimeoffId}/split
  `,
        commonData,
        getOptions()
      );
      if (res?.message) return openSnackbar('error', res.message);
      dispatch(getTimeoffs(null, null, weekDayFromO));
      openSnackbar('success', 'The Time off was updated successfully');
      closeDrawer();
    } catch (error) {
      openSnackbar('error', error.message);
    }
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <PageLoader loading={isSubmitting}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formFlex}>
          <div>
            <Typography variant="h3" style={{ marginBottom: '30px' }}>
              Edit Timeoff Request
            </Typography>

            <IconButton
              className={classes.closeButton}
              onClick={closeDrawer}
              aria-label="Close"
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>

            {user && (
              <TimeoffDetails
                control={control}
                errors={errors}
                values={values}
                userWeekends={calcUserWeekends()}
                user={user}
                onRemove={() => null}
                withoutRemove
                setError={setError}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            )}
          </div>

          <div className={classes.buttonHolder}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={closeDrawer}
              disabled={isSubmitting}
              disableTouchRipple
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className={classes.saveButton}
              disabled={isSubmitting || !!Object.keys(errors).length}
              disableTouchRipple
            >
              Save
            </Button>
          </div>
        </form>
      </PageLoader>
    </div>
  );
};

EditSeveralTimeoffsForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  data: propTypes.array.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(EditSeveralTimeoffsForm);
